import { Link } from "react-router-dom";
import { useState } from "react";
import { ReactComponent as YourSvg } from "./icons8-wordpress.svg";
import { ReactComponent as Twitter } from "./x-twitter-brands-solid.svg";
import { ReactComponent as Instagram } from "./instagram-brands-solid.svg";

const Publications = () => {
  const [currentLanguage, setCurrentLanguage] = useState(false);
  const [currentUsedLanguage, setCurrentUsedLanguage] = useState("EN");
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark mb-3">
        <button
          className="navbar-toggler text-light"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto">
            <li className="nav-item">
              <Link className="nav-link text-light" to="/">
                {currentUsedLanguage === "EN" ? "Home" : "Ana Sayfa"}
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link text-light" to="/publications">
                {currentUsedLanguage === "EN" ? "Articles" : "Yazılar"}
              </Link>
            </li>
            {/* <li className="nav-item">
              <a
                href="./Eyüp_Sercan_UYGUR_CV.pdf"
                className="nav-link text-light"
              >
                {" "}
                View CV
              </a>
            </li>
            <li>
              <a
                href="./Eyüp_Sercan_UYGUR_CV.pdf"
                className="nav-link text-light d-none d-lg-block"
                download="Eyüp Sercan UYGUR"
                id="download"
              >
                {" "}
                Download CV
              </a>
            </li> */}
          </ul>
        </div>
        <button
          type="button"
          className="btn btn-success btn-lg static-position"
          onClick={() => {
            {
              currentUsedLanguage === "EN"
                ? setCurrentUsedLanguage("TR")
                : setCurrentUsedLanguage("EN");
            }
            setCurrentLanguage(!currentLanguage);
          }}
        >
          {currentUsedLanguage === "EN" ? "TR" : "EN"}
        </button>
      </nav>
      <article className="text-light mb-2 article-3">
        <div className="container">
          <div className="row shadow">
            <div className="col-xl-8 col-12 heading order-2">
              <h5>How to Configure and Use GraphQL with Rails</h5>
              <p>
                In this article, I’ll briefly describe the basics of configuring
                GraphQL with Rails and the basics of the GraphQL query
                language...
              </p>
              <div className="d-flex mt-5 py-2 flex-wrap">
                <p className="mx-2 bg-info rounded labels py-1 px-2">Rails</p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">GraphQL</p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">Queries</p>
              </div>
              <div className="order-3">
                <a
                  href="https://sercanuygur.medium.com/how-to-configure-and-use-graphql-with-rails-7aecd0f30fec"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light btn btn-success mx-2 mb-4 button-article"
                >
                  {currentUsedLanguage === "EN"
                    ? "See The Article"
                    : "Yayını Gör"}
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-12 order-1">
              <img src="/graphql.png" alt="puzzle" className="w-100 py-4" />
            </div>
          </div>
          <div className="row shadow">
            <div className="col-xl-8 col-12 heading order-2">
              <h5>
                Three Design Patterns (Factory, Abstract Factory, Builder) and
                Their Usage with Ruby
              </h5>
              <p>If you all have is a hammer, everything becomes a nail…</p>
              <div className="d-flex mt-5 py-2 flex-wrap">
                <p className="mx-2 bg-info rounded labels py-1 px-2">
                  Design Patterns
                </p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">OOP</p>
              </div>
              <div className="order-3">
                <a
                  href="https://sercanuygur.medium.com/the-important-design-patterns-and-their-usage-with-ruby-4d111741a14f"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light btn btn-success mx-2 mb-4 button-article"
                >
                  {currentUsedLanguage === "EN"
                    ? "See The Article"
                    : "Yayını Gör"}
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-12 order-1">
              <img src="/design.jpeg" alt="puzzle" className="w-100 py-4" />
            </div>
          </div>
          <div className="row shadow">
            <div className="col-xl-8 col-12 heading order-2">
              <h5>How to Use Rails Backend with React Frontend</h5>
              <p>
                Rails is a great tool for writing an app from the bottom to the
                top. It’s MVC pattern is easy to grasp and can be very useful
                for separating the concerns. It has a lot of gems on top of each
                other to make the framework’s writing experience easier. Those
                gems and their duties is another topic, and I’ll write about
                them in another article...
              </p>
              <div className="d-flex mt-5 py-2 flex-wrap">
                <p className="mx-2 bg-info rounded labels py-1 px-2">Rails</p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">
                  Rails API
                </p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">React</p>
              </div>
              <div className="order-3">
                <a
                  href="https://sercanuygur.medium.com/how-to-use-rails-backend-with-react-frontend-8cc81302eb63"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light btn btn-success mx-2 mb-4 button-article"
                >
                  {currentUsedLanguage === "EN"
                    ? "See The Article"
                    : "Yayını Gör"}
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-12 order-1">
              <img src="/rails.jpeg" alt="puzzle" className="w-100 py-4" />
            </div>
          </div>
          <div className="row shadow mt-4">
            <div className="col-xl-8 col-12 heading order-2">
              <h5>Important Default Rails Gems and Their Usages</h5>
              <p>
                Rails is a pretty useful tool for building web apps. You can
                build your backend and frontend totally with it...
              </p>
              <div className="d-flex mt-5 py-2 flex-wrap">
                <p className="mx-2 bg-info rounded labels py-1 px-2">Rails</p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">
                  Ruby Gems
                </p>
              </div>
              <div className="order-3">
                <a
                  href="https://sercanuygur.medium.com/important-default-rails-gems-and-their-usages-53d1d4874bad"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light btn btn-success mx-2 mb-4 button-article"
                >
                  {currentUsedLanguage === "EN"
                    ? "See The Article"
                    : "Yayını Gör"}
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-12 order-1">
              <img src="/RoR_logo.png" alt="puzzle" className="w-100 py-4" />
            </div>
          </div>
          <div className="row shadow mt-4">
            <div className="col-xl-8 col-12 heading order-2">
              <h5>
                Teach Yourself How to Work With Bootstrap 4 in Ten Minutes or
                Less
              </h5>
              <p>
                “Bootstrap is a free and open-source CSS framework directed at
                responsive, mobile-first front-end web development. It contains
                CSS- and (optionally) JavaScript-based design templates for
                typography, forms, buttons, navigation, and other interface
                components.”  In this tutorial based article, you can learn how
                to setup Bootstrap 4 and use it with ease...
              </p>
              <div className="d-flex mt-5 py-2 flex-wrap">
                <p className="mx-2 bg-info rounded labels py-1 px-2">
                  Bootstrap
                </p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">SCSS</p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">Npm</p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">Flexbox</p>
              </div>
              <div className="order-3">
                <a
                  href="https://hackernoon.com/teach-yourself-how-to-work-with-bootstrap-4-in-ten-minutes-or-less-ok4o3u09"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light btn btn-success mx-2 mb-4 button-article"
                >
                  {currentUsedLanguage === "EN"
                    ? "See The Article"
                    : "Yayını Gör"}
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-12 order-1">
              <img
                src="/Bootstrap4.png"
                alt="Easy-Pay"
                className="w-100 py-4"
              />
            </div>
          </div>
        </div>
      </article>
      <article className="text-light mb-2 article-3">
        <div className="container">
          <div className="row shadow mt-4">
            <div className="col-xl-8 col-12 heading order-2">
              <h5>Everything You Need to Know About Bootstrap 5</h5>
              <p>
                "Bootstrap is a powerful front-end library, that gives you the
                power of quick design and responsive mobile-first site layout.
                It lays on top of the 12 grid system and it has extensive
                pre-built components." In this article, you can see the
                difference between Bootstrap 4 and 5 and understand the
                important changes that happened...
              </p>
              <div className="d-flex mt-5 py-2">
                <p className="mx-2 bg-info rounded labels py-1 px-2">
                  Bootstrap
                </p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">
                  CSS, SCSS
                </p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">Jquery</p>
              </div>
              <div className="order-3">
                <a
                  href="https://hackernoon.com/everything-you-need-to-know-about-bootstrap-5-yf423tf0"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light btn btn-success mx-2 mb-4 button-article"
                >
                  {currentUsedLanguage === "EN"
                    ? "See The Article"
                    : "Yayını Gör"}
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-12 order-1 d-flex align-items-center">
              <img
                src="/Bootstrap5.png"
                alt="Easy-Pay"
                className="w-100 h-75 py-3"
              />
            </div>
          </div>
        </div>
      </article>
      <article className="text-light mb-2 article-3">
        <div className="container">
          <div className="row shadow mt-4">
            <div className="col-xl-8 col-12 heading order-2">
              <h5>A Simple Guide for CSS Animations</h5>
              <p>
                "Animations are very useful tools to make pages more appealing.
                I would like to start with what are animations, give some
                supplementary information, and give some animation syntax
                examples." In this article, you can see some simple examples of
                CSS animations and learn why they are important?
              </p>
              <div className="d-flex mt-5 py-2">
                <p className="mx-2 bg-info rounded labels py-1 px-2">
                  CSS Animations
                </p>
                <p className="mx-2 bg-info rounded labels py-1 px-2">
                  CSS, SCSS
                </p>
              </div>
              <div className="order-3">
                <a
                  href="https://hackernoon.com/a-simple-guide-for-css-animations-6a363uby"
                  target="_blank"
                  rel="noreferrer"
                  className="text-light btn btn-success mx-2 mb-4 button-article"
                >
                  {currentUsedLanguage === "EN"
                    ? "See The Article"
                    : "Yayını Gör"}
                </a>
              </div>
            </div>
            <div className="col-xl-4 col-12 order-1">
              <img
                src="/animations.jpeg"
                alt="Easy-Pay"
                className="w-100 py-4"
              />
            </div>
          </div>
        </div>
      </article>
      <footer className="my-footer">
        <ul className="list-unstyled d-flex justify-content-center">
          <li>
            <a
              href="https://www.linkedin.com/in/eypsrcnuygr/"
              target="_blank"
              rel="noreferrer"
              className="text-light disabled-2"
            >
              <i className="fab fa-linkedin-in mx-2"></i>
            </a>
          </li>
          <li>
            <a
              href="https://github.com/Srjnnnn"
              target="_blank"
              rel="noreferrer"
              className="text-light disabled-2"
            >
              <i className="fab fa-github mx-2"></i>
            </a>
          </li>
          <li style={{ marginRight: "4px" }}>
            <a
              href="https://medium.com/@sercanuygur"
              target="_blank"
              rel="noreferrer"
              className="text-light disabled-2"
            >
              <i className="fab fa-medium-m mx-2"></i>
            </a>
          </li>
          <li id="twitter" style={{ marginRight: "4px" }}>
            <a
              href="https://twitter.com/Srjnnnn"
              target="_blank"
              rel="noreferrer"
              className="text-light disabled-2"
            >
              <Twitter id="twitter_elem" className="disabled-2 text-light" />
            </a>
          </li>

          <li id="instagram" style={{ marginRight: "4px" }}>
            <a
              href="https://www.instagram.com/srjnuygur/"
              target="_blank"
              rel="noreferrer"
              className="text-light disabled-2"
            >
              <Instagram
                id="instagram_elem"
                className="disabled-2 text-light"
              />
            </a>
          </li>

          <li id="wordpress" style={{ marginLeft: "4px" }}>
            <a
              href="https://wordpress.com/home/eyupsercanuygur.wordpress.com"
              target="_blank"
              rel="noreferrer"
              className="text-light disabled-2"
            >
              <YourSvg id="wordpress_elem" className="disabled-2 text-light" />
            </a>
          </li>
        </ul>
      </footer>
    </div>
  );
};

export default Publications;
