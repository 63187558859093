import "./App.css";
import Typewriter from "./Typewriter";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { ReactComponent as YourSvg } from "./icons8-wordpress.svg";
import { ReactComponent as Twitter } from "./x-twitter-brands-solid.svg";
import { ReactComponent as Instagram } from "./instagram-brands-solid.svg";
function App() {
  const [currentLanguage, setCurrentLanguage] = useState(false);
  const [currentUsedLanguage, setCurrentUsedLanguage] = useState("EN");
  const [inputStrings, setInputStrings] = useState([
    "Hello, I am a material scientist and a developer. Residing between Kusadasi and Istanbul, Turkey. Interested in music and poetry, love motorcycles and nature. Currently working at Gusto. Below are my skills. I can help you to build a product.",
  ]);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [shouldSubmitActive, setShouldSubmitActive] = useState(true);
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [checkState, setCheckState] = useState(false);
  const [isMailValid, setIsMailValid] = useState(false);
  const [subjectLine, setSubjectLine] = useState("");

  // const callBackFromChild = () => {};

  useEffect(() => {
    currentLanguage
      ? setInputStrings([
          "Merhabalar, Ben bir metalurji/malzeme ve yazılım mühendisiyim. Enerji malzemeleri ve yenilenebilir sistemler, metal ve kompozitlerin mekanik davranışları, metallerin korozif dayanımı, değerli metaller, termodinamik, üretken yapay zeka ve standart makine öğrenmesi ilgilendiğim alanlar. Hobilerim; müzik, doğa ve motosikletlerdir. Aşağıda yetkinliklerimi bulabilirsiniz. Bana sayfanın altındaki linklerden yada formu doldurarak ulaşabilirsiniz. Ürün geliştirmenize katkıda bulunabilirim.",
        ])
      : setInputStrings([
          "Hello, I am a metallurgical/materials and software engineer. I'm insterested in; energy materials and renewable systems, mechanic properties of composites and metals, corrosive resistance of metals, precious metals, thermodynamic, generative AI and standard machine learning. My hobbies are the music, nature and motorcycles. Down there, the list shows my skills. You can reach me via the links below or via filling the form. I can help you to build a product.",
        ]);
  }, [currentLanguage, setInputStrings]);

  useEffect(() => {
    if (isMailValid && checkState) {
      setShouldSubmitActive(false);
      setSubjectLine(
        `New message from ${formValues.email} on eyupsercanuygur.com`
      );
    } else {
      setShouldSubmitActive(true);
    }
  }, [isMailValid, checkState, formValues.email, subjectLine]);

  // const encode = (data) => {
  //   return Object.keys(data)
  //     .map(
  //       (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
  //     )
  //     .join("&");
  // };

  const ValidateEmail = (e) => {
    const email = document.forms[0]["email"].value;
    const pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;

    if (!email.match(pattern)) {
      setIsMailValid(false);
    } else {
      setIsMailValid(true);
    }
  };

  const handleChangeForCheck = (e) => {
    if (e.target.checked) {
      setCheckState(true);
    } else {
      setCheckState(false);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const myForm = event.target;
    const formData = new FormData(myForm);

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        alert(
          "Your message has been submitted! Now it's on the way to my mailbox :)"
        );
        window.location.reload(true);
      })
      .catch((error) => alert(error));
  };

  const handleChangeForName = (e) => {
    setFormValues({
      name: e.target.value,
      email: formValues.email,
      message: formValues.message,
    });
  };

  const handleChangeForEmail = (e) => {
    setFormValues({
      email: e.target.value,
      name: formValues.name,
      message: formValues.message,
    });
    ValidateEmail(e);
  };

  const handleChangeForMessage = (e) => {
    setFormValues({
      message: e.target.value,
      email: formValues.email,
      name: formValues.name,
    });
  };

  return (
    <div id="real-container">
      <div className="App">
        <nav className="navbar navbar-expand-lg navbar-dark">
          <button
            className="navbar-toggler text-light"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mx-auto">
              {/* <li className="nav-item">
                <Link className="nav-link text-light" to="/">
                  {currentUsedLanguage === "EN" ? "Home" : "Ana Sayfa"}
                </Link>
              </li> */}
              {/* <li className="nav-item">
                <Link className="nav-link text-light" to="/publications">
                  {currentUsedLanguage === "EN" ? "Articles" : "Yazılar"}
                </Link>
              </li> */}
              {/* <li className="nav-item">
              <a href="./Eyüp_Sercan_UYGUR_CV.pdf" className="nav-link text-light">
                {" "}
                View CV
              </a>
            </li>
            <li>
              <a
                href="./Eyüp_Sercan_UYGUR_CV.pdf"
                className="nav-link text-light d-none d-lg-block"
                download="Eyüp Sercan UYGUR"
                id="download"
              >
                {" "}
                Download CV
              </a>
            </li> */}
            </ul>
          </div>
          <button
            type="button"
            className="btn btn-success btn-lg static-position"
            onClick={() => {
              {
                currentUsedLanguage === "EN"
                  ? setCurrentUsedLanguage("TR")
                  : setCurrentUsedLanguage("EN");
              }
              setCurrentLanguage(!currentLanguage);
              setShouldRefresh(true);
            }}
          >
            {currentUsedLanguage === "EN" ? "Türkçe" : "English"}
          </button>
        </nav>
        <div className="d-flex justify-content-center pt-4">
          <img
            src="./ben2.jpeg"
            alt="me"
            className="rounded rounded-circle my-photo"
          />
        </div>
        <div className="d-flex justify-content-center pt-4">
          <img src="./Transparent.png" alt="expression" className="my-word" />
        </div>
        <div className="text-center pt-4">
          <h1 className="text-light font-weight-bolder">Eyüp Sercan UYGUR</h1>
        </div>
        <div className="text-center pt-4">
          <h2 className="text-light my-h2">
            {currentUsedLanguage === "EN"
              ? "Entrepreneur & Engineer"
              : "Girişimci & Mühendis"}
          </h2>
        </div>
        <div className="text-center pt-4 col-12 col-lg-8 mx-auto">
          <Typewriter
            className="text-light"
            inputStrings={[...inputStrings]}
            currentLanguage={currentLanguage}
            shouldRefresh={shouldRefresh}
            currentUsedLanguage={currentUsedLanguage}
          />
        </div>
        <div className="container-fluid container-lg mx-auto text-center mt-5">
          <h5
            style={{
              color: "#7d3fe8",
              fontSize: "1.7rem",
              fontWeight: "bold",
            }}
          >
            {currentUsedLanguage === "EN" ? "COMPETENCIES" : "YETKİNLİKLER"}
          </h5>
          <div className="row text-center mx-auto">
            <div className="col col-lg-4 text-center">
              <ul className="list-unstyled" style={{ display: "inline-grid" }}>
                <li>
                  <a
                    href="https://openclassrooms.com/en/course-certificates/4872473806"
                    rel="noreferrer"
                    target="blank"
                    className="text-light disabled"
                  >
                    JavaScript
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.credential.net/fd59c9a6-a0b0-4f1b-a001-735c9fbadea8#gs.eem28t"
                    rel="noreferrer"
                    target="blank"
                    className="text-light disabled"
                  >
                    Ruby
                  </a>
                </li>
                <li>
                  <p className="mb-0 text-light disabled">Python</p>
                </li>
                <li>
                  <p className="mb-0 text-light disabled">Matlab</p>
                </li>
                <li>
                  <p className=" mb-0 text-light disabled">Linux</p>
                </li>
                <li>
                  <p className=" mb-0 text-light disabled">
                    Tensorflow & PyTorch
                  </p>
                </li>
                <li>
                  <p className=" mb-0 text-light disabled">
                    ML/DL & Languge - Vision Models
                  </p>
                </li>
              </ul>
            </div>
            <div className="col col-lg-4 text-center">
              <ul className="list-unstyled">
                <li>
                  <p className=" mb-0 text-light disabled">
                    Docker & Kubernetes
                  </p>
                </li>
                <li>
                  <p className=" mb-0 text-light disabled">
                    PostgreSQL & MySQL & MongoDB
                  </p>
                </li>

                <li>
                  <a
                    href="https://openclassrooms.com/en/course-certificates/2235830313"
                    target="blank"
                    rel="noreferrer"
                    className="text-light disabled"
                  >
                    React
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.credential.net/b2805b3f-2003-4116-9831-f85855a5ed6e#gs.rq7gyo"
                    target="blank"
                    className="text-light disabled"
                  >
                    Rails
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.credential.net/b2805b3f-2003-4116-9831-f85855a5ed6e#gs.rq7gyo"
                    target="blank"
                    className="text-light disabled"
                  ></a>
                </li>
                <li>
                  <a
                    href="https://openclassrooms.com/en/course-certificates/4592073605"
                    target="blank"
                    className="text-light disabled"
                  >
                    <p className="mb-0 text-light disabled">Node.js</p>
                  </a>
                </li>
                <li className="text-light">Typescript</li>
                <li>
                  <p className=" mb-0 text-light disabled">REST & GraphQL</p>
                </li>
              </ul>
            </div>
            <div className="col col-lg-4 text-center mb-5">
              <ul className="list-unstyled">
                <li>
                  <p className=" mb-0 text-light disabled">
                    Computational Methods & Statistics
                  </p>
                </li>
                <li>
                  <p className=" mb-0 text-light disabled">Thermodynamic</p>
                </li>
                <li>
                  <p className=" mb-0 text-light disabled">
                    Extractive Metallurgy
                  </p>
                </li>
                <li>
                  <p className=" mb-0 text-light disabled">
                    XRD-CAP/EIS Analysis
                  </p>
                </li>
                <li>
                  <p className=" mb-0 text-light disabled">
                    Physical Metallurgy
                  </p>
                </li>
                <li className="text-light">Materials' Mechanic</li>
                <li className="text-light">Chemical Metallurgy</li>
              </ul>
            </div>
          </div>
          <div className="text-center d-flex flex-column align-items-center justify-content-center">
            <form
              name="contact"
              method="POST"
              onSubmit={handleSubmit}
              netlify-honeypot="bot-field"
              data-netlify="true"
              style={{
                padding: "20px",
                backgroundColor: "#3fe8e8",
                borderRadius: "5px",
                boxShadow: "2px 2px 2px 0px",
              }}
            >
              <input
                type="hidden"
                name="subject"
                value={subjectLine}
                className="hidden"
              ></input>
              <input type="hidden" name="form-name" value="contact" />
              <div className="form-group">
                <p className="hidden d-none">
                  <label>
                    Don’t fill this out if you’re human:{" "}
                    <input name="bot-field" />
                  </label>
                </p>
                <label htmlFor="Name_Area" className="text-white">
                  {currentUsedLanguage === "EN" ? "Your Name" : "Adınız"}
                </label>
                <input
                  type="name"
                  name="name"
                  className="form-control"
                  id="NameArea"
                  aria-describedby="Name_Area"
                  value={formValues.name}
                  onChange={(e) => handleChangeForName(e)}
                />
                <label htmlFor="exampleInputEmail1" className="text-white my-2">
                  {currentUsedLanguage === "EN"
                    ? "Email address"
                    : "Email adresi"}
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  name="email"
                  value={formValues.email}
                  onChange={(e) => handleChangeForEmail(e)}
                />

                {!isMailValid ? (
                  <small id="emailValidation" className="form-text text-danger">
                    {currentUsedLanguage === "EN"
                      ? "Please fill a valid email!"
                      : "Lütfen geçerli bir email adresi girin!"}
                  </small>
                ) : null}

                <small id="emailHelp" className="form-text text-muted">
                  {currentUsedLanguage === "EN"
                    ? "If you want to share something please fill these areas..."
                    : "Benimle paylaşmak istediğiniz bir şey varsa bu alanları doldurun..."}
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="Message_Area" className="text-white">
                  {currentUsedLanguage === "EN" ? "Message" : "Mesaj"}
                </label>
                <textarea
                  rows="5"
                  type="text"
                  className="form-control"
                  id="Message_Area"
                  name="message"
                  value={formValues.message}
                  onChange={(e) => handleChangeForMessage(e)}
                />
              </div>
              <div className="form-group form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                  value={checkState}
                  onChange={(e) => {
                    handleChangeForCheck(e);
                  }}
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  {currentUsedLanguage === "EN"
                    ? "Check me out!"
                    : "Beni Tıkla!"}
                </label>
              </div>
              <button
                type="submit"
                className="btn btn-success"
                disabled={shouldSubmitActive}
              >
                {currentUsedLanguage === "EN" ? "Submit" : "Gönder"}
              </button>
            </form>
          </div>
        </div>
        <footer className="my-footer">
          <ul className="list-unstyled d-flex justify-content-center">
            <li>
              <a
                href="https://www.linkedin.com/in/eypsrcnuygr/"
                target="_blank"
                rel="noreferrer"
                className="text-light disabled-2"
              >
                <i className="fab fa-linkedin-in mx-2"></i>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/Srjnnnn"
                target="_blank"
                rel="noreferrer"
                className="text-light disabled-2"
              >
                <i className="fab fa-github mx-2"></i>
              </a>
            </li>
            <li style={{ marginRight: "4px" }}>
              <a
                href="https://medium.com/@sercanuygur"
                target="_blank"
                rel="noreferrer"
                className="text-light disabled-2"
              >
                <i className="fab fa-medium-m mx-2"></i>
              </a>
            </li>
            <li id="twitter" style={{ marginRight: "4px" }}>
              <a
                href="https://twitter.com/Srjnnnn"
                target="_blank"
                rel="noreferrer"
                className="text-light disabled-2"
              >
                <Twitter id="twitter_elem" className="disabled-2 text-light" />
              </a>
            </li>

            <li id="instagram" style={{ marginRight: "4px" }}>
              <a
                href="https://www.instagram.com/srjnuygur/"
                target="_blank"
                rel="noreferrer"
                className="text-light disabled-2"
              >
                <Instagram
                  id="instagram_elem"
                  className="disabled-2 text-light"
                />
              </a>
            </li>

            <li id="wordpress" style={{ marginLeft: "4px" }}>
              <a
                href="https://wordpress.com/home/eyupsercanuygur.wordpress.com"
                target="_blank"
                rel="noreferrer"
                className="text-light disabled-2"
              >
                <YourSvg
                  id="wordpress_elem"
                  className="disabled-2 text-light"
                />
              </a>
            </li>
          </ul>
        </footer>
      </div>
    </div>
  );
}

export default App;
